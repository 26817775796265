import React, { ChangeEvent, useEffect, useState } from 'react';
import './Header.css';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import { FiSearch } from 'react-icons/fi';
import { AppState } from '../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import {
  getLoggedInEmployeeAction,
  setCurrentWorkshopAction,
  updateSearchPhraseAction,
} from './headerActions';
import LanguageSelector from '../languageSelector/LanguageSelector';
import { I18n, Translate } from 'react-redux-i18n';
import { Dropdown } from 'react-bootstrap';
import { ApiWorkshop } from '../../api';
import { push } from 'connected-react-router';
import { ActionButton } from '../controls/ActionButton';
import { logoutAction } from '../../auth/authenticationActions';

export interface HeaderProps {
  onlyLogo?: boolean;
  isAuthorized?: boolean;
}

export const Header: React.FC<React.PropsWithChildren<Props>> = ({
  onlyLogo = false,
  isAuthorized = true,
  logo,
  color,
  updateSearchPhrase,
  translations,
  employee,
  getLoggedInEmployee,
  setWorkshop,
  selectedWorkshop,
  redirectToLogin,
  redirectToCases,
  logout,
}: Props) => {
  const [text, setText] = useState(I18n.t('header.searchText'));

  if (isAuthorized) {
    useEffect(() => {
      (async () => getLoggedInEmployee())();
    }, []);
  }

  useEffect(() => {
    setText(I18n.t('header.searchText'));
  }, [translations]);

  const logoElement = (
    <img
      src={logo}
      onClick={isAuthorized ? redirectToCases : redirectToLogin}
      data-testid='app-logo'
      className='app-logo'
      alt='website logo'
    />
  );

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    updateSearchPhrase(input);
  };

  const handleWorkshopChange = (eventKey: string | null) => {
    eventKey && setWorkshop(parseInt(eventKey));
  };

  const searchBox = !onlyLogo && isAuthorized && (
    <Form className='align-left-25 ml-5'>
      <Row>
        <InputGroup size='sm'>
          <InputGroup.Prepend>
            <InputGroup.Text id='search-icon'>
              <FiSearch />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            id='search-input'
            placeholder={text}
            aria-label='Search'
            aria-describedby='search-icon'
            onChange={handleSearchInputChange}
          />
        </InputGroup>
      </Row>
    </Form>
  );

  const user = !onlyLogo && isAuthorized && (
    <Navbar.Text data-testid='user-profile'>{employee?.name}</Navbar.Text>
  );

  const workshops = !onlyLogo &&
    isAuthorized &&
    employee &&
    employee.workshops &&
    employee.workshops.length > 1 && (
      <Navbar.Text>
        <Dropdown>
          <Dropdown.Toggle
            id='workshops-dropdown'
            data-testid='workshops-dropdown-dt'
            style={{ backgroundColor: color, borderColor: color }}
          >
            {employee.workshops.find((workshop) => workshop.id === selectedWorkshop)?.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {employee.workshops.map((workshop: ApiWorkshop) => (
              <Dropdown.Item
                href='/cases'
                onSelect={handleWorkshopChange}
                key={workshop.id}
                eventKey={workshop.id}
              >
                {workshop.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Text>
    );

  return (
    <Navbar
      expand='sm'
      className='justify-content-between py-2'
      style={{ borderLeft: `4px solid ${color}` }}
    >
      <Navbar.Brand>{logoElement}</Navbar.Brand>
      {searchBox}
      <Navbar.Collapse className='d-flex justify-content-end'>
        {user}
        {workshops}
        <Navbar.Text>
          <LanguageSelector />
        </Navbar.Text>
        &nbsp;
        {isAuthorized && (
          <ActionButton
            outline={true}
            onClick={() => logout()}
            text={<Translate value={'logout'} />}
          />
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = ({ theme, i18n, header }: AppState) => ({
  logo: theme.logo,
  color: theme.color,
  translations: i18n.locale,
  employee: header.employee,
  selectedWorkshop: header.currentWorkshop,
});

const mapDispatchToProps = {
  updateSearchPhrase: updateSearchPhraseAction,
  getLoggedInEmployee: getLoggedInEmployeeAction,
  setWorkshop: setCurrentWorkshopAction,
  logout: logoutAction,
  redirectToLogin: () => push('/login'),
  redirectToCases: () => push('/cases'),
};

export type Props = HeaderProps &
  ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
