import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Translate } from 'react-redux-i18n';
import './WorkshopAdmin.css';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import Header from '../../components/header/Header';
import { TextInput } from '../../components/controls/TextInput';
import { PasswordInput } from '../../components/controls/PasswordInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  emailValidation,
  notEmptyValidation,
  passwordValidation,
} from '../../common/utils/FormValidations';
import NextButton from '../../components/controls/NextButton';
import { ModalPopup } from '../../components/controls/ModalPopup';
import { EmployeeNotFoundError } from '../../common/exceptions/EmployeeError';
import {
  addEmployeeAction,
  getEmployeeInfoAction,
  signUpToCognitoAction,
  WorkShopEmployeeCreationInfo,
} from './adminActions';

export const AddEmployee: React.FC<React.PropsWithChildren<AddEmployeeProps>> = ({
  currentWorkshop,
  getEmployeeInfo,
  addEmployee,
  signUpToCognito,
}: AddEmployeeProps) => {
  const [loading, setLoading] = useState(false);
  const [resultPopup, setResultPopup] = useState(<></>);
  const [userExists, setUserExists] = useState(true);
  const [emailEntered, setEmailEntered] = useState(false);
  const [lastCheckedEmail, setLastCheckedEmail] = useState('');
  const [employee, setEmployee] = useState<{
    name: string | undefined;
    phoneNumber: string | undefined;
  }>({
    name: '',
    phoneNumber: '',
  });
  const [alreadyAssigned, setAlreadyAssigned] = useState(false);

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(
      yup.object().shape(
        userExists
          ? {
              email: emailValidation,
            }
          : {
              name: notEmptyValidation,
              phone: notEmptyValidation,
              email: emailValidation,
              password: passwordValidation,
            }
      )
    ),
  });
  const { handleSubmit, errors, register } = methods;

  const submissionCleanUp = () => {
    setEmailEntered(false);
    setLastCheckedEmail('');
    setEmployee({ name: '', phoneNumber: '' });
    setUserExists(true);
    setAlreadyAssigned(true);
  };

  const onSubmit = handleSubmit(async (values: WorkShopEmployeeCreationInfo) => {
    if (alreadyAssigned) {
      return;
    }
    setLoading(true);

    try {
      if (!userExists) {
        await signUpToCognito(values.email, values.password);
      }

      const workshopId = currentWorkshop?.id;
      if (workshopId == undefined) {
        console.error('WorkshopId Can not be null');
        return;
      }
      await addEmployee(values, workshopId);
      submissionCleanUp();
      setResultPopup(
        <ModalPopup
          message={<Translate value={'addEmployee.success'} />}
          closeHandler={() => setResultPopup(<></>)}
        />
      );
    } catch (error: any) {
      setResultPopup(
        <ModalPopup
          message={'Error:\n' + (error.message ?? 'Sorry, something went wrong')}
          closeHandler={() => setResultPopup(<></>)}
        />
      );
    } finally {
      setLoading(false);
    }
  });

  const onEmailEntered = async (event) => {
    {
      const email = event.target.value;
      if (email.length === 0) {
        setEmailEntered(false);
        setLastCheckedEmail('');
        setAlreadyAssigned(false);
        return;
      }

      const workshopId = currentWorkshop?.id;

      if (lastCheckedEmail == email || workshopId == undefined) {
        return;
      }

      setLastCheckedEmail(email);
      setLoading(true);
      setEmailEntered(true);

      try {
        const employee = await getEmployeeInfo(email, workshopId);
        setEmployee({ name: employee.name, phoneNumber: employee.phoneNumber });
        setUserExists(employee.id !== undefined);
        const index = employee.workshops.findIndex((ws) => ws.id === workshopId);
        setAlreadyAssigned(index !== -1);
      } catch (e: any) {
        if (e instanceof EmployeeNotFoundError) {
          setUserExists(false);
          if (userExists) {
            setEmployee({ name: '', phoneNumber: '' });
          }
          setAlreadyAssigned(false);
          setEmployee({ name: undefined, phoneNumber: undefined });
        } else {
          console.error(e);
        }
      }
      setLoading(false);
    }
  };
  return (
    <div className='add-employee-container'>
      <div className={'h-50 d-flex flex-column justify-content-lg-between'}>
        <Header />

        <Container className={'w-50'}>
          <p className='add-employee-header'>
            <Translate value={'addEmployee.header'} />
          </p>

          <p>{currentWorkshop?.name}</p>
          <FormProvider {...methods}>
            <Form className='my-4' onSubmit={onSubmit}>
              <TextInput
                name='email'
                label={<Translate value={'addEmployee.email'} />}
                testId='email'
                controlId='email'
                isInvalid={!!errors.email}
                ref={register({ required: true })}
                onBlurHandler={onEmailEntered}
                validationError={<Translate value={'addEmployee.errors.email'} />}
              />
              {!emailEntered ? (
                <></>
              ) : (
                <TextInput
                  name='name'
                  label={<Translate value={'addEmployee.name'} />}
                  testId='name'
                  value={employee?.name}
                  controlId='name'
                  isInvalid={!!errors.name}
                  ref={register({ required: true })}
                  validationError={<Translate value={'addEmployee.errors.name'} />}
                />
              )}
              {!emailEntered ? (
                <></>
              ) : (
                <TextInput
                  name='phone'
                  label={<Translate value={'addEmployee.phone'} />}
                  testId='phone'
                  controlId='phone'
                  value={employee?.phoneNumber}
                  isInvalid={!!errors.phone}
                  ref={register({ required: true })}
                  validationError={<Translate value={'addEmployee.errors.phone'} />}
                />
              )}
              {!emailEntered || userExists ? (
                <></>
              ) : (
                <PasswordInput
                  name='password'
                  label={<Translate value={'addEmployee.password'} />}
                  testId='password'
                  controlId='password'
                  ref={register({ required: true })}
                  isInvalid={!!errors.password}
                  validationError={<Translate value={'addEmployee.errors.password'} />}
                />
              )}
              {resultPopup}
              {alreadyAssigned ? (
                <Translate
                  className='employee-already-assigned'
                  value={'addEmployee.errors.alreadyAssigned'}
                />
              ) : (
                <></>
              )}
              <NextButton
                type='submit'
                data-testid='submitBtn'
                loading={loading}
                disabled={Object.keys(errors).length != 0 || alreadyAssigned || !emailEntered}
                text={<Translate value={'addEmployee.saveEmployee'} />}
              />
            </Form>
          </FormProvider>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ header }: AppState) => ({
  currentWorkshop: header.employee?.workshops.find((ws) => ws.id == header.currentWorkshop),
  getEmployeeInfo: getEmployeeInfoAction,
  addEmployee: addEmployeeAction,
  signUpToCognito: signUpToCognitoAction,
});

export type AddEmployeeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(AddEmployee);
