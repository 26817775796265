import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Translate } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';

export interface Props {
  showModal: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const CancelCaseModal: React.FC<React.PropsWithChildren<Props>> = ({
  showModal,
  onSubmit,
  onClose,
}) => {
  const [show, setShow] = useState(showModal);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className='border-bottom-0' closeButton>
        <Modal.Title className='ml-auto'>
          <Translate value='caseDetail.cancelCase.modalTitle' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <Translate value='caseDetail.cancelCase.modalBody' />
      </Modal.Body>
      <Modal.Footer className='border-top-0'>
        <Button variant='outline-secondary' onClick={handleClose}>
          <Translate value='caseDetail.cancelCase.no' />
        </Button>
        <Button variant='outline-danger' onClick={onSubmit}>
          <Translate value='caseDetail.cancelCase.yes' />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
