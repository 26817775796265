import React from 'react';
import { CustomBadge } from '../../../components/controls/CustomBadge';
import './CaseDetailsInfoTab.css';
import { Translate } from 'react-redux-i18n';
import { ApiCaseStatus } from '../../../api';
import { FiArrowLeft } from 'react-icons/fi';

export interface CaseDetailsInfoTabProps {
  licensePlate: string;
  isClaimCase: boolean;
  status: ApiCaseStatus;
  redirectToCasesByTab: (tabKey: string) => void;
}

export const CaseDetailsInfoTab: React.FC<React.PropsWithChildren<CaseDetailsInfoTabProps>> = ({
  licensePlate,
  isClaimCase,
  status,
  redirectToCasesByTab,
}: CaseDetailsInfoTabProps) => {
  const redirectToCases = () => {
    const tabKey = isClaimCase ? 'claims' : 'assistance';
    redirectToCasesByTab(tabKey);
  };

  const statusTextColor = {
    PENDING: '#1d7d82',
    CANCELED: '#E4E2DF',
    COMPLETED: '#277D54',
  };

  const statusBackgroundColor = {
    PENDING: '#9adfe3',
    CANCELED: '#786D63',
    COMPLETED: '#9ce6ad',
  };

  return (
    <>
      <div className='w-50 ml-3 pt-2'>
        <FiArrowLeft
          data-testid='caseDetailsBackButton'
          onClick={redirectToCases}
          className='case-details-back-button'
          size={25}
        />
        <span className='case-details-info'>
          <Translate
            value={isClaimCase ? 'caseDetail.infoTab.claims' : 'caseDetail.infoTab.assistance'}
          />
        </span>
        <span className='ml-1'>/</span>
        <span className='case-details-info ml-1'>{licensePlate}</span>
        <CustomBadge
          text={<Translate value={`caseDetail.caseStatus.${status}`} />}
          textColor={statusTextColor[status]}
          backgroundColor={statusBackgroundColor[status]}
        />
      </div>
    </>
  );
};
