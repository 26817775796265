import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CaseDetailSectionTitle from '../case-detail-section/components/CaseDetailSectionTitle';
import { FaFilePdf } from 'react-icons/fa';
import { Translate } from 'react-redux-i18n';
import { ApiFile } from '../../../api/models';

export interface CaseDetailDocumentsProps {
  documents: ApiFile[];
}

export const CaseDetailDocumentSection: React.FC<
  React.PropsWithChildren<CaseDetailDocumentsProps>
> = ({ documents }: CaseDetailDocumentsProps) => {
  const documentList = (
    <div className='file-uploader'>
      {documents && documents.length > 0 ? (
        documents.map((file: ApiFile, index: number) => (
          <>
            <Row key={index} className='mb-2 ml-2'>
              <Col sm={12} className='my-auto h-auto d-flex justify-content-between '>
                <div className={''}>
                  <FaFilePdf size={25} color={'red'} />
                  <a href={file.url} target='_blank' rel='noreferrer' className={'ml-2'}>
                    {file.path}
                  </a>
                </div>
              </Col>
            </Row>
            <hr />
          </>
        ))
      ) : (
        <Container>
          <Translate value={'caseDetail.noData'} />
        </Container>
      )}
    </div>
  );

  return (
    <Container className='claim-section py-3 mt-1'>
      <CaseDetailSectionTitle
        title={<Translate value={'caseDetail.documentsSection.title'} />}
        showDivider={true}
      />
      {documentList}
    </Container>
  );
};

export default CaseDetailDocumentSection;
