import React from 'react';
import { Container } from 'react-bootstrap';
import CaseDetailSectionTitle from '../components/CaseDetailSectionTitle';
import CaseDetailSectionField from '../components/CaseDetailSectionField';
import '../CaseSection.css';
import { ApiOtherPartiesSection } from '../../../../api/models';
import { Translate } from 'react-redux-i18n';

export interface OtherPartiesSectionProps {
  otherPartiesSection: ApiOtherPartiesSection[];
}

interface OtherPartyProps {
  otherParty: ApiOtherPartiesSection;
  key: number;
}

const Party: React.FC<React.PropsWithChildren<OtherPartyProps>> = ({
  otherParty,
  key,
}: OtherPartyProps) => {
  return (
    <div key={key}>
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.otherPartiesSection.description'} />}
        body={otherParty.description}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.otherPartiesSection.name'} />}
        body={otherParty.name}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.otherPartiesSection.personalCode'} />}
        body={otherParty.personalCode}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.otherPartiesSection.phone'} />}
        body={(otherParty.phoneNumberPrefix || '') + (otherParty.phoneNumber || '')}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.otherPartiesSection.email'} />}
        body={otherParty.email}
      />
      <br /> <hr />
    </div>
  );
};

export const OtherPartiesSection = ({
  otherPartiesSection,
}: OtherPartiesSectionProps): JSX.Element => (
  <Container className='claim-section py-3 mt-1 mb-3'>
    <CaseDetailSectionTitle title={<Translate value={'caseDetail.otherPartiesSection.title'} />} />
    {otherPartiesSection.length > 0 ? (
      otherPartiesSection.map((party, index) => <Party key={index} otherParty={party} />)
    ) : (
      <Container>
        <Translate value={'caseDetail.noData'} />
      </Container>
    )}
  </Container>
);

export default OtherPartiesSection;
