import React, { useState } from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, ResolveThunks } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import { AppState } from '../../../store/store';
import CaseDetailSectionTitle from '../case-detail-section/components/CaseDetailSectionTitle';
import { saveCaseCommentAction } from '../caseDetailActions';

export interface CommentSubmitPayload {
  caseComment?: string;
}

export const CaseDetailCommentSection: React.FC<
  React.PropsWithChildren<CaseDetailCommentProps>
> = ({ color, caseId, comment, saveCaseComment }: CaseDetailCommentProps) => {
  const methods = useForm({
    defaultValues: {
      caseComment: comment ? comment : '',
    },
  });

  const { register, handleSubmit, formState } = methods;
  const [errorPopup, setErrorPopup] = useState(<></>);

  const onSubmit = handleSubmit(async (data: CommentSubmitPayload) => {
    try {
      caseId && (await saveCaseComment(caseId, data.caseComment));
    } catch (error: any) {
      setErrorPopup(
        <ModalPopup
          message={<Translate value='errors.savingCommentFailed' />}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    }
  });

  return (
    <Container className='claim-section py-3 mt-1 mb-3'>
      <CaseDetailSectionTitle
        title={<Translate value='caseDetail.commentsSection.title' />}
        showDivider
      />
      <FormProvider {...methods}>
        <Form className='my-4' noValidate onSubmit={onSubmit}>
          <Form.Group controlId='caseDetailComment'>
            <Form.Control
              as='textarea'
              rows={4}
              data-testid='caseDetailCommentBox'
              placeholder={I18n.t('caseDetail.commentsSection.commentBoxPlaceholder')}
              ref={register}
              name='caseComment'
            />
          </Form.Group>
          <Button
            type='submit'
            className='px-2 py-1 text-nowrap'
            style={{ backgroundColor: color, borderColor: color }}
            disabled={formState.isSubmitting}
            data-testid='submitBtn'
          >
            {formState.isSubmitting && (
              <Spinner animation='border' size='sm' as='span' role='status' />
            )}
            {formState.isSubmitting && ' '}
            <Translate value='caseDetail.commentsSection.save' />
          </Button>
        </Form>
      </FormProvider>
      {errorPopup}
    </Container>
  );
};

const mapStateToProps = ({ theme, cases, caseDetail }: AppState) => ({
  color: theme.color,
  caseId: cases.selectedCaseProgress?.id,
  comment: caseDetail.comment?.comment,
});

const mapDispatchToProps = {
  saveCaseComment: saveCaseCommentAction,
};

export type CaseDetailCommentProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetailCommentSection);
