import React from 'react';
import { Container } from 'react-bootstrap';
import CaseDetailSectionTitle from '../components/CaseDetailSectionTitle';
import CaseDetailSectionField from '../components/CaseDetailSectionField';
import '../CaseSection.css';
import {
  ApiCommunicationPreference,
  ApiCommunicationPreferenceType,
  ApiNotifier,
  ApiNotifierSection,
} from '../../../../api/models';
import { Translate } from 'react-redux-i18n';

export interface NotifierInfoSectionProps {
  notifierInfoSection: ApiNotifierSection | undefined;
  communicationPreference: ApiCommunicationPreference | undefined;
}

interface NotifierProps {
  notifier: ApiNotifier;
  communicationPreference?: ApiCommunicationPreference;
}

const Driver: React.FC<React.PropsWithChildren<NotifierProps>> = ({ notifier }: NotifierProps) => (
  <>
    <CaseDetailSectionField
      title={<Translate value={'caseDetail.notifierInfoSection.driverName'} />}
      body={notifier.name}
    />
    <CaseDetailSectionField
      title={<Translate value={'caseDetail.notifierInfoSection.driverPhone'} />}
      body={(notifier.phoneNumberPrefix || '') + notifier.phoneNumber}
    />
    <CaseDetailSectionField
      title={<Translate value={'caseDetail.notifierInfoSection.driverEmail'} />}
      body={notifier.email}
    />
    <CaseDetailSectionField
      title={<Translate value={'caseDetail.notifierInfoSection.driverPersonalCode'} />}
      body={notifier.personalCode}
    />
  </>
);

const Notifier: React.FC<React.PropsWithChildren<NotifierProps>> = ({
  notifier,
  communicationPreference,
}: NotifierProps) => {
  const getLocalizedPreferences = (
    preferenceTypes: ApiCommunicationPreferenceType[]
  ): JSX.Element => {
    if (
      preferenceTypes.includes(ApiCommunicationPreferenceType.Email) &&
      preferenceTypes.includes(ApiCommunicationPreferenceType.Sms)
    ) {
      return <Translate value={'caseDetail.communicationPreference.emailAndSms'} />;
    } else if (preferenceTypes.includes(ApiCommunicationPreferenceType.Email)) {
      return <Translate value={'caseDetail.communicationPreference.email'} />;
    } else {
      return <Translate value={'caseDetail.communicationPreference.sms'} />;
    }
  };

  return (
    <>
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.notifierInfoSection.name'} />}
        body={notifier.name}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.notifierInfoSection.phone'} />}
        body={(notifier.phoneNumberPrefix || '') + notifier.phoneNumber}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.notifierInfoSection.email'} />}
        body={notifier.email}
      />
      {communicationPreference != undefined && (
        <CaseDetailSectionField
          title={<Translate value={'caseDetail.communicationPreference.label'} />}
          bodyTestId='communication-pref'
          body={getLocalizedPreferences(communicationPreference.communicationPreferences)}
        />
      )}
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.notifierInfoSection.personalCode'} />}
        body={notifier.personalCode}
      />
    </>
  );
};

export const NotifierInfoSection = ({
  notifierInfoSection,
  communicationPreference,
}: NotifierInfoSectionProps): JSX.Element => {
  return notifierInfoSection == undefined ? (
    <></>
  ) : (
    <Container className='claim-section py-3 mt-1 mb-3'>
      <CaseDetailSectionTitle
        title={<Translate value={'caseDetail.notifierInfoSection.title'} />}
      />
      {notifierInfoSection.notifier != undefined && (
        <Notifier
          notifier={notifierInfoSection.notifier}
          communicationPreference={communicationPreference}
        />
      )}
      <br />
      {notifierInfoSection.driver != undefined && <Driver notifier={notifierInfoSection.driver} />}
    </Container>
  );
};

export default NotifierInfoSection;
