import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.css';

interface DateTimeInputProps {
  selectedDate?: Date;
  onChange: (date: Date) => void;
  minDateTime?: Date;
  maxDateTime?: Date;
}

const DATE_FORMAT = 'dd/MM/yyyy';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const DateInput: React.FC<React.PropsWithChildren<DateTimeInputProps>> = ({
  selectedDate,
  onChange,
  minDateTime,
  maxDateTime,
}: DateTimeInputProps) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      dateFormat={DATE_FORMAT}
      placeholderText={DATE_FORMAT}
      allowSameDay={true}
      minDate={minDateTime && minDateTime > new Date() ? minDateTime : new Date()}
      maxDate={maxDateTime}
      calendarStartDay={1}
    />
  );
};

export const DateTimeInput: React.FC<React.PropsWithChildren<DateTimeInputProps>> = ({
  selectedDate,
  onChange,
  minDateTime,
  maxDateTime,
}: DateTimeInputProps) => {
  const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(
    selectedDate ? new Date(selectedDate) : undefined
  );
  const minDate = minDateTime && minDateTime > new Date() ? minDateTime : new Date();

  const handleOnChange = (value: Date) => {
    setSelectedDateTime(value);
    if (value && !(value.getHours() === 0 && value.getMinutes() === 0)) {
      onChange(value);
    }
  };

  const filterPassedTime = (date: Date) => {
    return (
      !(date.getHours() === 0 && date.getMinutes() === 0) &&
      date > minDate &&
      (maxDateTime ? date < maxDateTime : true)
    );
  };

  const onClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      event &&
      selectedDateTime &&
      selectedDateTime.getHours() === 0 &&
      selectedDateTime.getMinutes() === 0
    ) {
      setSelectedDateTime(undefined);
    }
  };

  return (
    <DatePicker
      selected={selectedDateTime}
      onChange={handleOnChange}
      placeholderText={DATE_TIME_FORMAT}
      dateFormat={DATE_TIME_FORMAT}
      showTimeSelect
      timeFormat={TIME_FORMAT}
      timeIntervals={30}
      allowSameDay={true}
      minDate={minDate}
      maxDate={maxDateTime}
      filterTime={filterPassedTime}
      popperProps={{ strategy: 'fixed' }}
      calendarStartDay={1}
      onClickOutside={(event) => onClickOutside(event)}
    />
  );
};
