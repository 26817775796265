import React from 'react';
import { Spinner } from 'react-bootstrap';
import Button, { ButtonProps } from 'react-bootstrap/Button';

export interface ActionButtonProps {
  text?: string | JSX.Element;
  disabled?: boolean;
  outline?: boolean;
  loading?: boolean;
}

export const ActionButton: React.FC<React.PropsWithChildren<ButtonProps & ActionButtonProps>> = (
  props: ButtonProps & ActionButtonProps
) => {
  const { text, disabled, loading, outline = false } = props;

  return (
    <Button
      {...props}
      className={props.className + (outline ? ' outlineBtn' : ' actnBtn')}
      disabled={disabled || loading}
      data-testid={outline ? 'logoutBtn' : 'sendSmsLinkBtn'}
    >
      {loading && (
        <Spinner animation='border' size='sm' as='span' role='status' className='btn-spinner' />
      )}
      {loading && ' '}
      {text}
    </Button>
  );
};
