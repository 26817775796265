import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { I18n, Translate } from 'react-redux-i18n';
import './WorkshopLogin.css';
import { connect, ResolveThunks } from 'react-redux';
import { AppState } from '../../store/store';
import loginImg from '../../assets/login-image.png';
import Header from '../../components/header/Header';
import { TextInput } from '../../components/controls/TextInput';
import { PasswordInput } from '../../components/controls/PasswordInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { emailValidation, passwordValidation } from '../../common/utils/FormValidations';
import NextButton from '../../components/controls/NextButton';
import { AuthCredentials } from '../../auth/authentication';
import { ModalPopup } from '../../components/controls/ModalPopup';
import { login } from '../../auth/authenticationActions';
import { push } from 'connected-react-router';
import { getParamFromUrl } from '../../common/utils/URLUtils';

export const WorkshopLogin: React.FC<React.PropsWithChildren<WorkshopLoginProps>> = ({
  color,
  navigateToHome,
}: WorkshopLoginProps) => {
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(<></>);
  const [error] = useState<string | undefined>(undefined);

  const expired = getParamFromUrl('expired');
  useEffect(() => {
    if (expired === 'true') {
      setErrorPopup(
        <ModalPopup
          message={I18n.t('errors.sessionExpiredLoginAgain')}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    }
  }, [expired]);

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(
      yup.object().shape({
        email: emailValidation,
        password: passwordValidation,
      })
    ),
  });
  const { handleSubmit, errors, register } = methods;

  // eslint-disable-next-line
  const onSubmit = handleSubmit(
    async (values: {
      email: string;
      password: string;
    }) => {
    setLoading(true);
    const credentials: AuthCredentials = {
      email: values.email,
      password: values.password,
    };

    try {
      await login(credentials);
      navigateToHome();
    } catch (error: any) {
      setErrorPopup(
        <ModalPopup
          message={'Error:\n' + (error.message ?? 'Sorry, something went wrong')}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    } finally {
      setLoading(false);
    }
  });

  return error ? (
    <div className='d-flex justify-content-center'>
      <p className='vertical-center'>{<Translate value={'errors.tryAgain'} />}</p>
    </div>
  ) : (
    <div className='login-container'>
      <Row>
        <Col lg={6}>
          <div className={'h-50 d-flex flex-column justify-content-lg-between'}>
            <Header onlyLogo={true} isAuthorized={false} />
            <Container className={'w-50'}>
              <FormProvider {...methods}>
                <Form className='my-4' onSubmit={onSubmit}>
                  <TextInput
                    name='email'
                    label={<Translate value={'login.email'} />}
                    testId='email'
                    controlId='email'
                    isInvalid={!!errors.email}
                    ref={register({ required: true })}
                    validationError={<Translate value={'login.errors.email'} />}
                  />

                  <PasswordInput
                    name='password'
                    label={<Translate value={'login.password'} />}
                    testId='password'
                    controlId='password'
                    ref={register({ required: true })}
                    isInvalid={!!errors.password}
                    validationError={<Translate value={'login.errors.password'} />}
                  />
                  {errorPopup}
                  <NextButton
                    type='submit'
                    data-testid='submitBtn'
                    loading={loading}
                    text={<Translate value={'login.login'} />}
                  />
                </Form>
              </FormProvider>
            </Container>
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ backgroundColor: color, boxSizing: 'border-box' }}>
            <img src={loginImg} className={'flex-3 fill-img img-shadow w-100'} title='image' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

const mapDispatchToProps = {
  navigateToHome: () => push('/'),
};

export type WorkshopLoginProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopLogin);
