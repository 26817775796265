import { Button, Container, Row, Spinner } from 'react-bootstrap';
import CaseDetailSectionTitle from '../case-detail-section/components/CaseDetailSectionTitle';
import { ApiFile } from '../../../api/models';
import { AiOutlineDownload } from 'react-icons/ai';
import { getCaseImages } from '../caseDetailActions';
import { connect } from 'react-redux';
import { AppState } from '../../../store/store';
import React, { useCallback, useEffect, useState } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import './CaseDetailImageSection.css';
import { Translate } from 'react-redux-i18n';

export type CasePhotoItem = {
  src: string;
  width: number;
  height: number;
  caption: string;
  srcSet: string | undefined;
  key: string;
  alt: string;
};

export interface CaseDetailImageSectionProps {
  themeColor: string;
  caseId: number | undefined;
  files: ApiFile[];
  getCaseImagesZipped: (caseId: number | undefined) => Promise<void>;
}

const CaseDetailImageSection: React.FC<CaseDetailImageSectionProps> = ({
  getCaseImagesZipped,
  themeColor,
  caseId,
  files,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState<CasePhotoItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const photos: CasePhotoItem[] = files.map((file: ApiFile) => ({
      src: file.url,
      width: 1,
      height: 1,
      caption: '',
      srcSet: '',
      key: file.id.toString(),
      alt: file.path,
    }));
    setPhotos(photos);
  }, []);

  const downloadImages = async () => {
    setLoading(true);
    await getCaseImagesZipped(caseId);
    setLoading(false);
  };

  const downloadIcon = () => {
    return (
      <>
        <AiOutlineDownload
          className='icon'
          size={15}
          onClick={downloadImages}
          style={{ color: 'white' }}
          data-testid='downloadBtn'
        />
      </>
    );
  };

  const spinner = () => {
    return (
      <>
        <Spinner className='icon' animation='border' size='sm' data-testid='spinner' />
      </>
    );
  };

  const openLightbox = useCallback((event: any, { index }: any) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const renderImageList = () => {
    return (
      <>
        <div className='file-uploader'>
          {photos && photos.length > 0 ? (
            <>
              <Gallery direction={'row'} photos={photos} onClick={openLightbox} />
              <Row>
                {
                  <ModalGateway>
                    {viewerIsOpen && (
                      <Modal allowFullscreen={false} onClose={closeLightbox}>
                        <Carousel
                          currentIndex={currentImage}
                          views={photos.map((photo) => ({
                            ...photo,
                            srcset: photo.srcSet,
                            caption: photo.caption,
                            source: photo.src,
                          }))}
                        />
                      </Modal>
                    )}
                  </ModalGateway>
                }
              </Row>
            </>
          ) : (
            <Container>
              <Translate value={'caseDetail.noData'} />
            </Container>
          )}
        </div>
      </>
    );
  };

  return (
    <Container className='claim-section py-3 mt-1'>
      <Row className='ml-1 pr-2'>
        <CaseDetailSectionTitle
          title={<Translate value={'caseDetail.photos'} />}
          showDivider={true}
        />
        {files && files.length > 0 && (
          <div className='pr-2 download-btn' style={{ flex: 'auto' }}>
            <Button
              className='btn-text '
              style={{ backgroundColor: themeColor, borderColor: themeColor }}
              size='sm'
              onClick={downloadImages}
            >
              {loading ? spinner() : downloadIcon()}
              <Translate value={'caseDetail.downloadAll'} />
            </Button>
          </div>
        )}
      </Row>

      {renderImageList()}
    </Container>
  );
};

// Export the unconnected component for testing
export { CaseDetailImageSection };

// Map state and dispatch to props
const mapStateToProps = ({ theme, cases, caseDetail }: AppState) => ({
  themeColor: theme.color,
  caseId: cases.selectedCaseProgress?.id,
  files: caseDetail.filesSection,
});

const mapDispatchToProps = {
  getCaseImagesZipped: getCaseImages,
};

// Export the connected component
export default connect(mapStateToProps, mapDispatchToProps)(CaseDetailImageSection);
