import React from 'react';
import { FormCheck } from 'react-bootstrap';

export interface SwitchButtonProps {
  id: string;
  checkedColor: string;
  isSwitched: boolean;
  onChange: (isChecked: boolean) => void;
}

export const SwitchButton: React.FC<React.PropsWithChildren<SwitchButtonProps>> = ({
  id,
  checkedColor,
  isSwitched,
  onChange,
}: SwitchButtonProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    onChange(isChecked);
  };

  return (
    <>
      <style type='text/css'>
        {`
        .custom-switch .custom-control-input:checked~.custom-control-label::before {
            background-color: ${checkedColor} !important;
            border-color: ${checkedColor};
         }
        `}
      </style>
      <FormCheck
        custom
        bsCustomPrefix={'custom-control'}
        checked={isSwitched}
        onChange={handleChange}
        type={'switch'}
        id={id}
      />
    </>
  );
};
export default SwitchButton;
